@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@200;300;400;500;700&display=swap');
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../public/fonts/Lato-Thin.ttf') format('truetype');
  font-weight: 100;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../public/fonts/Lato-Light.ttf') format('truetype');
  font-weight: 300;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../public/fonts/Lato-Regular.ttf') format('truetype');
  font-weight: 400;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../public/fonts/Lato-Bold.ttf') format('truetype');
  font-weight: 700;
}
@font-face {
  font-family: 'Lato';
  src: local('Lato'), url('../public/fonts/Lato-Black.ttf') format('truetype');
  font-weight: 900;
}

h1,
h2,
h3,
h4 {
  font-family: 'Lexend', sans-serif;
}

h5,
h6,
p,
li {
  font-family: 'Lato', sans-serif;
}

.MuiTab-root {
  padding-top: 32px !important;
  padding-bottom: 32px !important;
  padding-left: 24px !important;
  text-align: start !important;
  width: 210px !important;
  font-family: 'Lexend' !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 500 !important;
  text-transform: none !important;
  color: #081130 !important;
}

.Mui-selected {
  background-color: #f5f7ff !important;
}

.MuiTabs-indicator {
  background-color: #081130 !important;
  height: 4px !important;
}

.MuiPaper-root {
  background: none !important;
  box-shadow: none !important;
}

.MuiAccordionSummary-root {
  padding: 0 !important;
}

.MuiAccordionDetails-root {
  padding: 0 !important;
  padding-bottom: 24px !important;
}

.MuiAccordionSummary-expandIconWrapper {
  min-width: 32px !important;
}

.Mui-expanded {
  margin-top: 10px !important;
  margin-bottom: 0 !important;
}

.scrollbar-hide {
  /* IE and Edge */
  -ms-overflow-style: none;

  /* Firefox */
  scrollbar-width: none;
  scrollbar-color: transparent transparent;
}

/* Safari and Chrome */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
